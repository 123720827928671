import React from "react";
import Header from "./components/Header";
import MainBanner from "./components/MainBanner";
import QuienesSomos from "./components/QuienesSomos";
import Servicios from "./components/Servicios";
import CuerpoMedico from "./components/CuerpoMedico";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="App">
      <Header scrollToSection={scrollToSection} />
      <MainBanner />
      <QuienesSomos />
      <Servicios />
      <CuerpoMedico />
      <Footer />
    </div>
  );
}

export default App;
