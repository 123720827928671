import React from "react";
import "./Servicios.css";
const Servicios = () => {
  const serviciosData = [
    {
      title: "Tensión Ocular Aplanática",
      description: [
        "Es la medida de la presión intraocular.",
        "15 Minutos.",
        "1201014x2",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: [
        "No debe suspender ningún tratamiento ocular o general a excepción que su médico se lo haya indicado.",
        "Si usa lentes contacto debe retirarlos antes del examen. (Traer estuche)",
        "No se puede realizar junto a exámenes que requieran dilatación pupilar.",
      ],
    },
    {
      title: "Campimetría O Campo Visual Computarizado (CVC)",
      description: [
        "Examen que permite medir la sensibilidad umbral y el área visual de la retina.",
        "30 Minutos.",
        "1201042x2",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: [
        "No debe suspender ningún tratamiento ocular o general a excepción que su médico se lo haya indicado.",
        "Si usa lentes contacto debe retirarlos antes del examen. (Traer estuche)",
        "No se puede realizar junto a exámenes que requieran dilatación pupilar.",
      ],
    },
    {
      title: "Campimetría O Campo Visual Goldman",
      description: [
        "Examen que permite detectar en forma cinética y supraumbral el área y forma de nuestro campo de visión periférico y central.",
        "30 Minutos.",
        "1201010x2",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: [
        "Traer lentes de lejos y cerca o última receta indicada por su médico.",
        "Si usa lentes de contacto debe traerlos o la receta de estos.",
        "No se puede realizar con dilatación pupilar.",
      ],
    },
    {
      title: "Paquimetria Ultrasonica",
      description: [
        "Examen indoloro que permite medir el grosor de la córnea.",
        "30 Minutos.",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: [
        "Si usa lentes de contacto se debe suspender 2 semanas y presentarse sin ellos al examen (cuando se requiere para evaluación de cirugía refractiva).",
      ],
    },
    {
      title: "Estudio De Estrabismo",
      description: [
        "Examen que se realiza para evaluar el estado motor y sensorial de una desviación ocular.",
        "30 Minutos.",
        "1201009",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: [
        "Traer sus últimos lentes recetados (ópticos o de contacto) al examen. En el caso de niños, deben usarlos previo 2 semanas antes del examen para su adaptación por posibles cambios en la desviación con sus lentes y poder registrarlas en el examen.",
      ],
    },
    {
      title: "Test De Diplopia O Diploscopia",
      description: [
        "Examen que se realiza para el estudio de pacientes cuando presentan visión doble.",
        "30 Minutos.",
        "1201005",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: [
        "Traer sus lentes de lejos o venir con sus lentes de contacto.",
      ],
    },
    {
      title: "Test De Schirmer",
      description: [
        "Examen que se realiza para cuantificar la lágrima en un determinado lapso de tiempo.",
        "15 Minutos.",
        "1201003x2",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: ["Si usa lentes de contacto, traer su estuche y líquido."],
    },
    {
      title: "Curva De Tensión Aplanática O CTA",
      description: [
        "Permite determinar la variación de la presión intraocular a diferentes horas durante el día. Puede ser de 1 o 2 días según lo indique su médico. Son 3 tomas de presión intraocular, comienza a las 8:30 AM",
        "8:30 a 13:30 Hrs.",
        "1202004x2 (1 Día)",
        "1201004x4 (2 Días)",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: [
        "No debe suspender ningún tratamiento ocular o general a excepción que su médico se lo haya indicado.",
        "Traer anteojos o última receta de lentes indicada por su médico.",
        "Si usa lentes de contacto debe retirarlos antes del examen.",
        "No se puede realizar junto a exámenes que requieran dilatación pupilar.",
      ],
    },
    {
      title: "Tratamiento Ortópico",
      description: [
        "Serie de ejercicios oculares en sesiones semanales que se realizan para mejorar la motilidad ocular en algunos casos especiales de estrabismo.",
        "La cantidad de sesiones son habitualmente indicadas por el oftalmólogo tratante.",
        "Duración: 30 Minutos.",
        "1201015 x Número de sesiones",
      ],
    },
    {
      title: "Indicaciones Para El Paciente",
      description: [
        "Traer sus últimos lentes recetados (ópticos o de contacto).",
      ],
    },
  ];

  return (
    <div className="servicios-section" id="servicios">
      <h2 className="section-header">Servicios</h2>
      <div className="servicios-grid">
        {serviciosData.map((servicio, index) => (
          <div
            className={`servicios-item ${
              index % 2 === 0 ? "servicios-item-white" : "servicios-item-gray"
            }`}
            key={index}
          >
            <h1 className="servicios-title">{servicio.title}</h1>
            {index % 2 === 0 ? (
              <>
                <h3>Descripción</h3>
                <p>{servicio.description[0]}</p>
                <h3>Duración</h3>
                <p>{servicio.description[1]}</p>
                <h3>Código</h3>
                <p>{servicio.description[2]}</p>
              </>
            ) : (
              servicio.description.map((paragraph, idx) => (
                <p key={idx}>{paragraph}</p>
              ))
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Servicios;
