import React from "react";
import "./CuerpoMedico.css";
import gino from "../imgs/gino2.jpg";

const CuerpoMedico = () => {
  return (
    <div className="cuerpo-medico-section" id="cuerpo-medico">
      <div className="row-medico">
        <div className="column-photo">
          <img src={gino} alt="" className="person-photo" />
        </div>
        <div className="column-txt">
          <h2 className="section-heading">Cuerpo Médico</h2>
          <h3 className="section-subheading">Descripción del equipo médico</h3>
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-item">
          <h2 className="grid-heading">Dra. Lissette Caballero</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dr. Wilfredo Garcia Alvarez</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dra. Linsdy Hernández</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dra. Liliana Kiraz De Nava</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dr. Carlos Van Grieken</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dr. Jorge Tamayo Garcia</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dra. Eylym Sanchez Sálcedo</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dra. Greylin Palencia</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dra. Ruth Soto</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dra. Beatriz Garrido</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Dra. Dayana de Freitas</h2>
          <h3 className="grid-subheading">Médico Oftalmólogo</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Nicole Ovalle</h2>
          <h3 className="grid-subheading">Tecnólogo Médico</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Juan Sebastián Videla</h2>
          <h3 className="grid-subheading">Tecnólogo Médico</h3>
        </div>
        <div className="grid-item">
          <h2 className="grid-heading">Romina Caro Diaz</h2>
          <h3 className="grid-subheading">Tecnólogo Médico</h3>
        </div>
      </div>
    </div>
  );
};

export default CuerpoMedico;
