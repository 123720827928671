import React from "react";
import image1 from "../imgs/mission.png";
import image2 from "../imgs/vision.png";
import image3 from "../imgs/valores.png";
import "./QuienesSomos.css";

const QuienesSomos = () => {
  return (
    <div className="quienes-somos-section" id="quienes-somos">
      <h2 className="section-header">Quienes Somos</h2>
      <div className="quienes-somos-columns">
        <div className="column-inner">
          <img src={image1} alt="Image 1" className="column-image" />
          <h3 className="column-heading">Misión</h3>
          <p className="column-paragraph">
            Nuestra misión, es entregar a la comunidad una atención
            oftalmológica integral y de alta calidad, con aranceles razonables.
            Contamos con una moderna infraestructura, amplios espacios,
            tecnología de punta y personal altamente capacitado, orientado al
            trato acogedor y oportuno del paciente oftalmológico.
          </p>
        </div>
        <div className="column-inner">
          <img src={image2} alt="Image 2" className="column-image" />
          <h3 className="column-heading">Visión</h3>
          <p className="column-paragraph">
            Nuestros esfuerzos están enfocados en lograr el crecimiento
            constante del servicio entregado a la sociedad, siendo reconocidos
            por los pacientes y colaboradores.
          </p>
        </div>
        <div className="column-inner">
          <img src={image3} alt="Image 3" className="column-image" />
          <h3 className="column-heading">Valores</h3>
          <p className="column-paragraph">
            Como una entidad cuya base principal es el compromiso,
            responsabilidad y mayormente el trabajo humano y profesional que en
            conjunto se ha dirigido a la entrega de un servicio de calidad que
            muestra respeto e interés por los pacientes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuienesSomos;
