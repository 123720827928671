import React from "react";
import "./Header.css";
import logo from "../imgs/Logo.png";

const Header = ({ scrollToSection }) => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="" height="64px" />
      </div>
      <nav className="nav-menu">
        <input type="checkbox" id="menu-toggle" className="menu-toggle" />
        <label htmlFor="menu-toggle" className="menu-icon">
          <span></span>
          <span></span>
          <span></span>
        </label>
        <ul className="nav-links">
          <li>
            <a onClick={() => scrollToSection("inicio")}>Inicio</a>
          </li>
          <li>
            <a onClick={() => scrollToSection("quienes-somos")}>
              Quienes Somos
            </a>
          </li>
          <li>
            <a onClick={() => scrollToSection("servicios")}>Servicios</a>
          </li>
          <li>
            <a onClick={() => scrollToSection("cuerpo-medico")}>
              Cuerpo Médico
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
