import React from "react";
import "./MainBanner.css";
import img2 from "../imgs/bannerA.png";
import img1 from "../imgs/BannerB.jpg";
import logo1 from "../imgs/Fonasa.jpg";
import logo2 from "../imgs/consalud.png";
import logo3 from "../imgs/cruzblanca.png";
import video1 from "../imgs/Banner 3.mp4";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  BsFillTelephoneFill,
  BsClockFill,
  BsFillPinMapFill,
} from "react-icons/bs";

const MainBanner = () => {
  const openPopup = () => {
    const url =
      "https://agendapro.com/iframe/overview/enVQbWlOTFZTTjVJYTFxTWcydUQ5dz09LS1zSDFMSkI4WjZVSnZXQktqSzJMNkJRPT0=--2ec4ddba4cb6f87bcc836d9b2335d47431f11908";
    const width = 800; // Set your desired width
    const height = 600; // Set your desired height
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const options = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;

    window.open(url, "_blank", options);
  };

  return (
    <div className="main-banner" id="inicio">
      <div className="left-column">
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          showArrows={false}
        >
          <div>
            <img src={img1} alt="Image 1" className="banner-image" />
          </div>
          <div>
            <img src={img2} alt="Image 2" className="banner-image" />
          </div>
          <div>
            <video
              src={video1}
              controls
              className="banner-video"
              width={"100%"}
              autoPlay={true}
              loop={true}
            />
          </div>
        </Carousel>
      </div>
      <div className="right-column">
        <div className="row row-top">
          <div className="column">
            <h2>Horarios de Atención</h2>

            <BsClockFill color="white" size={"24px"} />
            <h3>Lunes a Viernes 08:30 a 18:45​</h3>
          </div>
        </div>
        <div className="row row-middle">
          <div className="column">
            <h2>Visítanos</h2>
            <BsFillPinMapFill color="white" size={"24px"} />
            <h3>Llano Subercaseaux 3811</h3>
          </div>
        </div>
        <div className="row row-bottom">
          <div className="column">
            <h2>Llámanos</h2>
            <BsFillTelephoneFill color="white" size={"24px"} />
            <h3>22 555 0727</h3>
            <h3>22 556 3581</h3>
            <h3>22 555 0638</h3>
          </div>
        </div>
      </div>
      <div className="bottom-row">
        <div className="column-main">
          <h2>Reserva de Horas</h2>
          <p>
            Servicios que no correspondan a consultas generales o pediatría, se
            deben agendar vía telefónica.
          </p>
          <p>
            Para la toma de horas de exámenes se debe contactar con una
            operadora telefónica que dará las indicaciones correspondientes al
            examen que solicite.
          </p>
          <p>Para toda reserva se debe presentar 10 minutos antes.</p>
          <button className="btn" onClick={openPopup}>
            RESERVA DE HORA
          </button>
        </div>
        <div className="column-main">
          <h2>Para toma de horas se debe considerar lo siguiente</h2>
          <p>
            Si usa lentes de contacto blandos debe dejar de utilizarlos 24 horas
            y sin son semirrígidos 48 horas.
          </p>
          <p>
            Para fondo de ojo y pacientes menores de 10 años, Solicitar hora en
            el horario Para fondo de ojo y pacientes menores de 10 años,
            solicitar hora en el horario de mañana antes de las 11 am y en el
            horario de la tarde antes de las 5 pm.
          </p>
          <p>
            Pacientes con alguna patología específica deben solicitar hora vía
            telefónica.
          </p>
          <p>* Dr. Garcia atiende niños de 6 años en adelante.</p>
          <p>* Dra. Kiraz atiende niños de 5 años en adelante.</p>
          <p>* Dra. Paz no atiende niños menores de 15 años.</p>
        </div>
        <div className="column-main">
          <h2>Convenios</h2>
          <div className="logos">
            <img src={logo1} alt="Logo 1" height="128px" />
            <img src={logo2} alt="Logo 2" height="64px" />
            <img src={logo3} alt="Logo 3" height="128px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
